:global {
  .settingLink.active {
    background: #edf0f2;
    color: #023047;
    border-right: 5px solid #023047;
    > svg {
      path {
        stroke: #023047;
      }
    }
  }
}

.setting {
  background: #ffffff;
  height: 100%;
  display: flex;
  justify-content: space-between;
  &_navbar {
    width: 242px;
    height: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e9edf5;
    > a {
      transition: 0.3s;
      height: 60px;
      padding-left: 24.5px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #888888;
      border-right: 5px solid transparent;
      > span {
        margin-left: 12px;
      }
    }
  }
  &_container {
    width: calc(100% - 242px);
    height: 100%;
    overflow-y: auto;
    padding-bottom: 32px;
  }
}
