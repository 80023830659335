:global {
  .kategorySettingModal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      .ant-modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #464255;
      }
    }
    .ant-form-item-explain {
      display: none !important;
    }
    .ant-form-item-label > label {
      &::before {
        display: none !important;
      }
    }
    .ant-row {
      margin-bottom: 16px;
    }
    &_title {
      display: flex;
      align-items: center;
      > label {
        border: 1px solid #e5e7eb;
        border-radius: 50px;
        margin-left: 19px;
        padding: 8px 24px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #023047;
        cursor: pointer;
        user-select: none;
        > svg {
          margin-right: 10px;
        }
      }
    }
    form {
      margin-top: 10px;
    }
  }
  .kategorySettingCollapse {
    margin-top: 16px;
    background: #ffffff;
    border: none;
    .ant-collapse-item {
      margin-bottom: 8px;
      border: none;
      .ant-collapse-header {
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 6px !important;
        align-items: center;
        > div {
          .ant-collapse-arrow {
            top: 35%;
          }
          &:nth-child(2) {
            display: flex;
            align-items: center;
            > span {
              background: #f1f1f1;
              width: 50px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
            > b {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: #1e3f5c;
              margin-left: 20px;
            }
          }
        }
        .ant-collapse-extra {
          > button {
            border: none;
            background: transparent;
          }
        }
      }
      .ant-collapse-content {
        border: 1px solid #dcdcdc;
        margin: 0 10px;
        border-radius: 0 0 6px 6px;
        background: #fcfcfc;
        border-top: none;
        .ant-collapse-content-box {
          padding: 15px 60px;
        }
      }
    }
  }
}
.kategorySetting {
  padding: 20px 32px;
  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #474747;
    }
  }
}
