:global {
  .navbar_links {
    > a.active {
      background: #023047;
      border-radius: 8px;
      color: #ffffff;
      > svg {
        path,
        rect {
          stroke: #ffffff;
        }
      }
    }
  }
}

.navabar {
  background: #ffffff;
  box-shadow: 1px 0px 1px rgba(200, 200, 200, 0.25);
  border-right: 1px solid #e9edf5;
  width: 258.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  > div {
    display: flex;
    flex-direction: column;
    > img {
      margin-bottom: 24px;
    }
    > a {
      transition: 0.3s;
      width: 194px;
      height: 48px;
      border-top: 1px solid #f7f7f7;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #888888;

      > svg {
        margin: 0 16px;
      }
    }
  }
  > button {
    border: none;
    background: transparent;
    cursor: pointer;
    width: 194px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #888888;
    > svg {
      margin-right: 16px;
    }
    &:focus {
      outline: none;
    }
  }
}
