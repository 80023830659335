:global {
  .auksionSettingForm {
    .ant-form-item-label {
      .ant-form-item-required {
        &::before {
          display: none !important;
        }
      }
    }
    .ant-form-item-explain {
      display: none;
    }
    .ant-form-item {
      margin: 0;
    }
  }
}

.auksionSetting {
  padding: 24px 32px;
  > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #474747;
    margin-bottom: 16px;
  }
  > form {
    margin: 0 24px;
  }
  &_container {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto;
    margin-bottom: 33px;
    > div {
      padding: 12px 16px;
      background: #ffffff;
      border: 1px solid #dfdfe6;
      border-radius: 6px;
    }
  }
}
