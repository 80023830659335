.payments {
  &_header {
    display: flex;
    justify-content: flex-end;
    padding: 32px 20px 16px;
    :global {
      button {
        border: 1px solid #023047;
        border-radius: 20px;
        padding: 8px 24px;
        height: unset;
        &:not(:last-child) {
          margin-right: 8px;
        }
        svg {
          margin-right: 4px;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #023047;
        }
      }
    }
  }
}

.makingPaymentModal {
  :global {
    .ant-modal-content {
      border-radius: 12px;
      overflow: hidden;
      .ant-modal-body {
        padding-bottom: 0;
      }
      .ant-select {
        width: 126px;
        &-selector {
          border-radius: 6px;
        }
      }
      .createButton {
        float: right;
      }
    }
  }
}
