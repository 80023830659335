.customPagination {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 9px 24px;
  margin-top: 8px;
  width: max-content;
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    background: #023047;
    border-radius: 20px;
    svg {
      > path {
        fill: #ffffff;
      }
    }
  }
  .ant-pagination-item {
    border-radius: 20px;
    border: none;
  }
  .ant-pagination-item-active {
    background: #023047;
    border-radius: 20px;
    > a {
      color: #ffffff;
    }
  }
  // .ant-pagination-options {
  //   display: none;
  // }
}
