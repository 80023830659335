:global {
  .usersAll_table {
    .ant-table-row {
      cursor: pointer;
    }
  }
  .userConfirmationModal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-radius: 12px 12px 0px 0px;
      .ant-modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #464255;
      }
    }
    .ant-modal-body {
      .userConfirmationModalContent {
        display: flex;
        > div {
          &:nth-child(1) {
            > p {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              color: #636363;
              margin-bottom: 8px;
            }
          }
          &:nth-child(2) {
            margin-left: 80px;
            > h1 {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              color: #636363;
              margin-bottom: 8px;
            }
            > div {
              display: grid;
              grid-template-columns: auto auto;
              grid-gap: 16px;
              > div {
                > h1 {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 15px;
                  color: #3d3d3d;
                  margin-bottom: 4px;
                }
                > p {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 15px;
                  color: #3d3d3d;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .user-append-modal {
    margin-top: -100px;
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-radius: 12px 12px 0px 0px;
      .ant-modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #464255;
      }
    }
    .ant-modal-body {
      padding: 0 0 16px;
    }
    &_title {
      display: flex;
      justify-content: space-between;
      padding: 12px 33px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      > button {
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        width: 220px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #474747;
        &:hover,
        &:focus {
          border: 1px solid #e5e7eb;
        }
      }
      .active {
        background: #3772ff;
        color: #ffffff;
      }
    }
    &_header {
      display: flex;
      justify-content: center;
      padding: 12px 0;
      > p {
        margin: 0 12px;
        border-bottom: 3px solid #e6e6e6;
        padding-bottom: 8px;
        cursor: default;
      }
    }
  }
}

.usersAll {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-bottom: 10px;
  &_header {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      align-items: center;
      display: flex;
    }
  }
}
