.usersInfo {
  &_container {
    padding: 22px 32px;
    border-bottom: 1px solid #e9edf5;
    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #1e3f5c;
      }
      > button {
        height: auto;
        padding: 9px 28px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #1e3f5c;
        border: 1px solid #1e3f5c;
        border-radius: 80px;
      }
    }
    &_body {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 32px 75px;
      padding: 17px;
      &_item {
        > h1 {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #3d3d3d;
        }
        > p {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #3d3d3d;
          > span {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #1eb53a;
          }
        }
        > h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #1eb53a;
        }
      }
    }
  }
}
