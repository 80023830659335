@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}
*::-webkit-scrollbar {
  width: 3px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: #023047;
  border-radius: 3px;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.d-f {
  display: flex;
}
.a-i-c {
  align-items: center;
}
.a-i-l {
  align-items: flex-start;
}
.a-i-r {
  align-items: flex-end;
}

.j-c-c {
  justify-content: center;
}

.j-c-l {
  justify-content: flex-start;
}

.j-c-r {
  justify-content: flex-end;
}
.j-c-s-b {
  justify-content: space-between;
}
.cur-p {
  cursor: pointer;
}
.w300 {
  width: 300px;
}
.m0 {
  margin: 0;
}
.w32 {
  width: 32px;
}
.h32 {
  height: 32px;
}

.customTable {
  thead {
    > tr {
      > th {
        padding: 12px;
        background: #ffffff;
        border-top: none;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #464f60;
      }
    }
  }
  tbody {
    > tr {
      > td {
        padding: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #464f60;
      }
      &:nth-child(even) {
        > td {
          background: #fbfbfb;
        }
      }
      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }
}

.filterButton {
  margin-right: 16px;
  width: 40px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  padding: 0;
  > svg {
    width: 12px;
  }
}

.searchInput {
  border-radius: 6px;
  .ant-input-prefix {
    margin-right: 9px;
  }
}

.createButton {
  background: #023047;
  border-radius: 50px;
  padding: 8px 33px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  height: auto;
}
.customInput {
  border-radius: 6px;
}
.customSelect {
  .ant-select-selector {
    border-radius: 6px !important;
  }
}
.saveButton {
  border-radius: 50px;
  padding: 8px 28px;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.customWhiteButton {
  background: #ffffff;
  border-radius: 50px;
  padding: 8px 28px;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #023047;
  border: none;
}
.customWhiteCircleButton {
  background: #ffffff;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #023047;
  border: none;
  padding: 0;
}
.customRedColorButton {
  background: transparent;
  border-radius: 50px;
  padding: 8px 28px;
  height: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ff0000;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-right: 10px;
  }
  &:hover,
  &:focus {
    color: #ff0000;
  }
}

.CollapseSortablePanel {
  width: 100%;
  margin-bottom: 8px;
  height: 70px;
  transition: 0.3s ease-in-out;
  &_header {
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 6px;

    > div {
      &:nth-child(1) {
        display: flex;
        align-items: center;
        > span {
          margin-right: 10px;
          background: #f1f1f1;
        }
        > b {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #1e3f5c;
        }
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        .ant-btn-circle {
          background: transparent;
          border: none;
        }
        > span {
          transition: 0.3s;
          margin: 0 10px;
          transform: rotate(180deg);
          cursor: pointer;
        }
        > button:last-child {
          cursor: move;
          > svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  &_content {
    border-top: none;
    background: #fcfcfc;
    margin: 0 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    > div {
      border: 1px solid #dcdcdc;
      padding: 16px 60px;
      border-radius: 0 0 6px 6px;
    }
    &_item {
      display: flex;
      align-items: center;
      > button {
        &:nth-child(1) {
          margin-right: 10px;
          cursor: move;
          > svg {
            transform: rotate(90deg);
          }
        }
      }
      .ant-btn-circle {
        margin-left: 10px;
        border: none;
      }
    }
  }
}
.CollapseSortablePanel.active {
  .CollapseSortablePanel_header {
    > div {
      &:nth-child(2) {
        > span {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.customDeleteModal {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-body {
      padding: 33px 42px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        background: #ffebeb;
        border-radius: 35px;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #23262f;
        padding: 24px 0;
      }
      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        > button {
          width: 149px;
          height: 36px;
          border: none;
          &:nth-child(1) {
            background: #f2f2f2;
            border-radius: 50px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #23262f;
          }
          &:nth-child(2) {
            background: #ff3f40;
            border-radius: 50px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #fcfcfd;
          }
        }
      }
    }
  }
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }
}

.passportTag {
  background: #3772ff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  width: 74px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lotsFilter {
  .ant-drawer-body {
    padding: 0;
  }
  &_header {
    padding: 17px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #474747;
      margin-bottom: 0;
    }
    > div {
      display: flex;
      > button {
        width: auto;
        padding: 0;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: #3772ff;
        cursor: pointer;
        margin-left: 12px;
        position: relative;
        &:last-child {
          &::before {
            content: '';
            width: 3px;
            height: 3px;
            left: -7px;
            top: 50%;
            background: #bdbdbd;
            position: absolute;
            border-radius: 50%;
          }
        }
      }
    }
  }
  &_search {
    padding: 0 18px 17px;
  }
  .ant-collapse {
    border: none;
    .ant-collapse-item {
      background: #ffffff;
      border: none;
    }
    .ant-collapse-header {
      // padding: 17px 18px;
      margin: 0 18px;
      padding: 12px 24px 12px 0;
      background: #ffffff;
      border-bottom: 1px solid #dadada;
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
        border: none;
      }
    }
    .ant-collapse-arrow {
      right: 0 !important;
    }
    .ant-collapse-content {
      padding: 0 18px;
      border: none;
      background: #f7f8f9;
    }
    .ant-collapse-content-box {
      // border-bottom: 1px solid #dadada;
      padding: 16px 0;
    }
  }
}
.lotsTableSize {
  .ant-table-content {
    overflow-y: auto;
    height: calc(100vh - 220px);
    .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}

.customDatePickerCont {
  display: flex;
  position: relative;
  .calendar_icon {
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 4px;
    cursor: pointer;
  }
}

.selected {
  background-color: rgb(2, 48, 71, 0.1) !important;
  &:hover {
    background-color: rgb(2, 48, 71, 0.1) !important;
  }
}
