:global {
  .userLotsTab {
    .ant-tabs-tab-btn {
      padding: 0 20px;
    }
    .ant-tabs-tab {
      border-bottom: 1px solid transparent;
    }
    .ant-tabs-tab-active {
      border-bottom: 2px solid #1e3f5c;
    }
  }
}
.userLots {
  &_body {
    padding: 10px 32px 16px;
    &_content {
      margin-bottom: 24px;
      > h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #474747;
        margin-bottom: 8px;
      }
      &_item {
        border: 1px solid #dfdfe6;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        margin-bottom: 8px;
        &_left {
          display: flex;
          align-items: center;
          > h1 {
            min-width: 131px;
            background: #023047;
            backdrop-filter: blur(8px);
            border-radius: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
            margin-bottom: 0;
            padding: 3px 16px;
            display: flex;
            align-items: center;
            > span {
              text-align: center;
              font-size: 12px;
              line-height: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 16px;
            }
          }
          > p {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #1e3f5c;
            margin-bottom: 0;
            margin-left: 16px;
          }
        }
        &_right {
          display: flex;
          align-items: center;
          > div {
            // margin-right: 40px;
            width: 200px;
            > h1 {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #3d3d3d;
              margin-bottom: 4px;
            }
            > p {
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 15px;
              color: #3d3d3d;
              margin-bottom: 0;
            }
          }
          > h1 {
            width: 116px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #14804a;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            > svg {
              margin-right: 9px;
            }
          }
          > p {
            width: 116px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #fc2e2e;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            > svg {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
}
