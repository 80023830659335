:global {
  .paymentType {
    background: #e1fcef;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #14804a;
    padding: 1px 10px;
  }
  .paymentCreateModal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-radius: 12px 12px 0px 0px;
      border-bottom: none;
      .ant-modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #464255;
      }
    }
    .ant-modal-body {
      padding: 0px 16px 16px 16px;
      > form {
        .searchInput {
          margin-bottom: 8px;
        }
        .paymentCreateModal_users_item {
          margin-top: 0px;
          margin-bottom: 16px;
        }
        .ant-form-item-required {
          &::before {
            display: none;
          }
        }
      }
    }
    .ant-form-item {
      margin-bottom: 8px;
      .ant-form-item-explain {
        display: none;
      }
    }
    &_users {
      background: #fcfcfd;
      border: 1px solid #dfdfe6;
      border-radius: 6px;
      padding: 19px;
      &_item {
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        display: flex;
        padding: 12px;
        margin-top: 4px;
        > span {
          width: 34px;
          height: 34px;
          background: #f9f9f9;
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        > div {
          margin-left: 16px;
          > h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #023047;
            margin-bottom: 1px;
          }
          > p {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            color: #8c8c8c;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.payments {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-bottom: 10px;
  &_header {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      align-items: center;
      display: flex;
    }
    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > button {
        border-radius: 20px;
        margin-left: 8px;
        width: 152px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #023047;
        > svg {
          margin-right: 6px;
        }
      }
    }
  }
}
