.contracts {
  margin: 16px;
  &_body {
    background: #ffffff;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    &_top {
      padding: 16px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_left {
        align-items: center;
        display: flex;
      }
    }
  }
}
