:global {
  .ACTIVE,
  .NEW,
  .WAIT {
    background: #e1fcef;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #14804a;
    padding: 1px 10px;
    margin: 0 auto;
  }
  .COMPLETED,
  .CANCELED,
  .FAILED {
    background: #606060;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #ffffff;
    padding: 1px 10px;
  }
  .lot_modal {
    .ant-modal-content {
      border-radius: 12px;
      .ant-form-item-required {
        &::before {
          display: none !important;
        }
      }
      .ant-form-item-explain-connected {
        display: none !important;
      }
      .ant-row {
        margin-bottom: 16px;
      }
    }
    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      border-bottom: none;
      .ant-modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #464255;
      }
    }
    &_title {
      display: flex;
      justify-content: center;
      align-items: center;
      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8c8c8c;
        margin: 0 12px;
        padding-bottom: 8px;
        border-bottom: 3px solid #e6e6e6;
        cursor: pointer;
      }
      .active {
        border-bottom: 3px solid #14804a;
      }
    }
    &_image {
      &_title {
        > h1 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #474747;
          margin-bottom: 8px;
        }
        > p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #888888;
          margin-bottom: 8px;
        }
      }
    }
    &_document {
      > h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #474747;
        margin-bottom: 8px;
      }
      &_file {
        background: #ffffff;
        border: 1px dashed #3772ff;
        border-radius: 6px;
        width: 100%;
        height: 147px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        &_reject,
        &_accept {
          border: 3px dashed #00000050;
        }
        > div {
          display: flex;
          align-items: center;
          user-select: none;
          > div {
            margin-left: 20px;
            > h1 {
              font-style: normal;
              font-weight: 600;
              font-size: 9px;
              line-height: 16px;
              color: #3772ff;
              margin-bottom: 0;
            }
            > p {
              font-style: normal;
              font-weight: 400;
              font-size: 7px;
              line-height: 8px;
              color: rgba(0, 0, 0, 0.4);
              margin-bottom: 0;
            }
          }
        }
      }
      &_files {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        padding: 13px 18px;
        margin-bottom: 8px;
        > div {
          display: flex;
          align-items: center;
          > div {
            margin-left: 14px;
            > h1 {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              color: #474747;
              margin-bottom: 0;
            }
            > p {
              font-style: normal;
              font-weight: 400;
              font-size: 7px;
              line-height: 8px;
              color: rgba(0, 0, 0, 0.4);
              margin-bottom: 0;
            }
          }
        }
        > button {
          border: none;
        }
      }
    }
    .close_auction {
      .ant-form-item-label {
        padding-bottom: 4px;
        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #636363;
        }
      }
      &_participants {
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 16px;
        h3 {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #636363;
        }
        .participant_cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 13px;
          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #262626;
            margin: 0;
            cursor: pointer;
          }
          .right {
            display: flex;
            .cup {
              display: flex;
              cursor: pointer;
              margin-right: 16px;
              &.winner {
                svg path {
                  fill: #ffac2f;
                }
              }
            }
            .delete {
              display: flex;
              width: 16px;
              height: 16px;
              cursor: pointer;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .winner {
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #636363;
        }
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #262626;
          &.null {
            color: #c2c2c2 !important;
          }
        }
      }
    }
  }
  .DragImageItem {
    border-radius: 6px;
    width: 92px;
    height: 65px;
    label {
      cursor: pointer;
    }
    .ant-avatar {
      width: 100%;
      height: 100%;
      background: #fafafa;
      border: 1px solid #e5e7eb;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.lots {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-bottom: 10px;
  &_header {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      align-items: center;
      display: flex;
    }
  }
}
