.contractModal {
  width: 704px !important;
  :global {
    .ant-modal-content {
      border-radius: 12px;
      .ant-modal-body {
        padding: 0;
      }
    }
    .error_input {
      input {
        border-color: #ff4d4f !important;
        &:focus {
          box-shadow: 0 0 0 2px var(--ant-error-color-outline);
        }
      }
    }
    .ant-form-item-required {
      &::before {
        display: none !important;
      }
    }
    .ant-form-item-explain {
      display: none !important;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 24px;
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #464255;
        margin: 0;
      }
      button {
        display: flex;
        width: 17px;
        height: 17px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    .body {
      padding: 0 24px;
      height: 70vh;
      overflow-y: auto;
      .little_title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        font-feature-settings: 'salt' on;
        color: #023047;
        margin-bottom: 8px;
      }
      .ant-form-item-label {
        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          font-feature-settings: 'salt' on;
          color: #8c8c8c;
        }
      }
      input {
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
      }
      .contract_date {
        margin-bottom: 16px;
        .ant-row {
          margin: 0;
        }
        input {
          width: 320px;
        }
        .calendar_icon {
          left: 284px;
        }
      }
      .saler_cont {
        &_header {
          display: flex;
          h4 {
            margin-right: 8px;
          }
          .ant-select {
            width: 84px;
            &-selector {
              display: flex;
              border: 1px solid #dfdfe6;
              border-radius: 4px;
              background: #fafafa;
              align-items: center;
              height: 20px;

              font-weight: 400;
              font-size: 10px;
              line-height: 12px;

              color: #474747;
            }
          }
        }
        .passport_cont {
          display: flex;
          align-items: flex-end;
          padding-bottom: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid #efefef;
          .ant-row {
            margin: 0;
          }
          .passpart_number_item {
            .ant-form-item-label {
              margin-left: -54px;
            }
          }
          .passport_seria {
            width: 50px;
            margin-right: 4px;
          }
          .passport_number {
            width: 175px;
            margin-right: 16px;
          }
          .customDatePickerCont {
            margin-right: 16px;
            input {
              width: 160px;
            }
            .calendar_icon {
              left: 124px;
            }
          }
          .searchBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3772ff;
            border-color: #3772ff;
            flex: 1;
            border-radius: 80px;
            svg {
              margin-right: 8px;
              path {
                fill: #ffffff;
              }
            }
          }
        }
        .physicalSalerCont {
          .ant-row {
            margin-bottom: 16px;
          }
          .customDatePickerCont {
            margin-right: 16px;
            .passport_given_date {
              input {
                width: 208px;
              }
            }
          }
          .calendar_icon {
            bottom: 20px;
            left: 192px;
          }
          .passport_given_place {
            flex: 1;
          }
        }
        .legalSalerCont {
          .ant-row {
            margin-bottom: 16px;
          }
          .doubleInput {
            display: flex;
            .ant-row {
              flex: 0.5;
              &:first-child {
                margin-right: 16px;
              }
            }
          }
        }
      }
      .productInfoCont {
        .ant-row {
          margin-bottom: 16px;
        }
        .price_date {
          display: flex;
          .ant-row {
            flex: 1;
            margin-right: 16px;
          }
          .customDatePickerCont {
            flex: 1;
            .calendar_icon {
              bottom: 20px;
              left: 274px;
            }
          }
        }
        #product_description {
          border-radius: 6px;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    }
  }
}
