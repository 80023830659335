:global {
  .kompanySetingForm {
    margin-top: 24px;
    .ant-form-item-required {
      &::before {
        display: none !important;
      }
    }
    .ant-form-item-explain {
      display: none !important;
    }
    .ant-row {
      margin-bottom: 16px;
    }
  }
}

.kompanySetting {
  padding: 24px 69px;
  &_title {
    display: flex;
    align-items: center;
    > label {
      border: 1px solid #e5e7eb;
      border-radius: 50px;
      margin-left: 19px;
      padding: 8px 24px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #023047;
      cursor: pointer;
      user-select: none;
      > svg {
        margin-right: 10px;
      }
    }
  }
  > form {
    width: 100%;
    margin-top: 16px;
  }
  &_grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 24px;
  }
}
