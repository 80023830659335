.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(200, 200, 200, 0.25);
  z-index: 1;
  position: relative;
  height: 52px;
}
