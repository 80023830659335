.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  &_left {
    width: 50%;
    height: 100%;
    background: #023047;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
    > img {
      pointer-events: none;
      position: absolute;
      &:nth-child(1) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:nth-child(2) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &_right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 482px;
      > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        color: #023047;
        text-align: center;
        margin-bottom: 44px;
      }
      .loginInput {
        border-radius: 12px;
        width: 482px;
        height: 56px;
        > input {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }
      }
      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #219ebc;
        padding: 10px 0 32px;
        width: 100%;
        cursor: pointer;
      }
      > button {
        background: #023047;
        border-radius: 50px;
        width: 100%;
        height: 56px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #fcfcfd;
      }
    }
  }
}
