:global {
  .adminSettingModal {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      .ant-modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #464255;
      }
    }
    .ant-form-item-explain {
      display: none !important;
    }
    .ant-form-item-label > label {
      &::before {
        display: none !important;
      }
    }
    .ant-row {
      margin-bottom: 16px;
    }
  }
}
.adminSetting {
  &_title {
    padding: 17px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #474747;
      margin-bottom: 0;
    }
  }
  &_container {
    margin: 18px 56px;
    background: #ffffff;
    overflow: hidden;
    position: relative;
    border: 1px solid #dfdfe6;
    border-radius: 6px;
  }
}
.status_blocked {
  background: #ffbdbd;
  border-radius: 10px;
  padding: 1px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #fc2e2e;
  &_active {
    background: #e1fcef;
    border-radius: 10px;
    padding: 1px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #14804a;
  }
}
